import React from 'react';
//import Layout from '../components/layout';
import { MDBContainer } from 'mdbreact';
import { MDBRow } from 'mdbreact';
import { MDBCol } from 'mdbreact';
import SEO from '../components/seo';
import PriestImage from '../images/PriestImage.png';
import Pageheader from '../Utils/pageheader';
const Ourpriest = () => {
    return (
        <>
            <MDBContainer className="mt-4">
                <SEO title="Our Priest | Hindu Temple Association of Iowa" />
                <MDBRow >
                    <MDBCol md="12">
                        <h1 className="title font-weight-bolder text-primary pt-2">Priest Sri Sheshashai B. Srinivasa</h1>
                        <Pageheader />
                    </MDBCol>
                    <MDBRow >
                        <MDBCol md="4" className="px-4 m-2 float-left">
                            <img className="img-responsive" src={PriestImage} alt="Our Priest" />
                            <h5 className="title"><strong className="text-primary">Phone:</strong> <span >319-396-3196 (Temple)</span> <br /> <span> <strong className="text-primary">Cell:</strong> 319-775-1552 </span> <br /><strong className="text-primary">Email:</strong> priest@iowahindutemple.org</h5>
                        </MDBCol>
                        <MDBCol md="7" className="px-4 m-2 float-left">
                            <p className="lead text-justify">Please meet our priest, Sri. Sheshashai B. Srinivasa, from Addagal, Srinivaspur Taluk, in the state of Karnataka, India. Sri Sheshashai started in this Temple in February 2012.</p>

                            <p className="lead text-justify"> Priest Sheshashai has over 20 years of experience as a priest. Prior to joining this temple, he has served as a Priest at Sri Venkateswara Temple, Devangere, Karnataka, India and in the United States at Pomona, New York and Orlando, Florida.</p>
                            
                            <p className="lead text-justify"> Sri Sheshashai has completed Pancharatra Agama Pravara from Maharaja Sanskrit College, Mysuru and graduated as SHASTRY from Rashtriya Sanskrit Vidyapeetha, Tirupathi in Chittoor district in the state of Andhra Pradesh, India.</p>

                            <p className="lead text-justify"> Sri Sheshashai is well-trained and very knowledgeable and an expert at performing various religious services such as Abhishekam, Gruhapravesha, Satyanarayana Puja, Sudharshana Homa, NavagrahaPuja and Homa, Rudraabhishekam, Kalyanotsavam, Namakarnam,Annaprashanam, Ayushya Homa and many more.</p>

                            <p className="lead text-justify"> Sri Sheshashai is fluent in English, Hindi, Kannada, Tamil, Telugu and Sanskrit. He is married and has three children.</p>

                            <p className="lead text-justify"> Sri Sheshashai is available for in-temple, outside-temple and also virtual services except on Thursdays when the Temple remains closed. Please call Temple at 319-396-3196 or mobile phone at 319-775-1552 or to discuss the needed Pooja service prior to filling the Pooja Request form.</p>
                            <p className="lead text-danger text-justify">
                                Thanks, <br />
                                Chair-Person, The Hindu Temple Association of Eastern Iowa.
                            </p>
                        </MDBCol>
                    </MDBRow>
                </MDBRow>

            </MDBContainer>

        </>
    );
};

export default Ourpriest;